<template>
  <div>
    <v-overlay :value="exibirLoading" :absolute="true">
      <v-progress-circular indeterminate size="32" color="#462181" width="2"
        ><v-progress-circular
          indeterminate
          size="24"
          color="#ff7f00"
          width="2"
          rotate="180"
        ></v-progress-circular
      ></v-progress-circular>
    </v-overlay>
    <div
      id="supersetMount"
      :key="keyUpdate"
      style="width: 100%; height: 950px"
      class="mt-5"
    ></div>
  </div>
</template>

<script>
import { SET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module";
import config from "@/core/config/layout.config.json";
import SupersetServices from "../../../core/services/SupersetServices";
import Utils from "../../../core/utils/Utils";
import ApiService from "../../../core/services/api.service";

// import { embedDashboard } from "@superset-ui/embedded-sdk";

export default {
  name: "bi",
  data() {
    return {
      supersetDomain: "",
      idDashboard: "",
      token: "",
      interval: null,
      supersetEmbeddedSdk: null,
      exibirLoading: true,
      keyUpdate: 0
    };
  },
  mounted() {
    // this.inicializar();
  },
  watch: {
    "$route.params.id": {
      handler: function (search) {
        this.keyUpdate++;
        this.inicializar();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    async fetchGuestTokenFromBackend(id) {
      let data = await SupersetServices.pegarTokenBi(id);
      this.exibirSuperset = true;
      this.supersetDomain = data.supersetDomain;
      this.idDashboard = data.idDashboard;

      return data.token;
    },
    async inicializar() {
      this.exibirLoading = true;
      this.iniciarLayout();
      let id = this.$route.params.id;
      let dados = await this.solicitarCodigoDashboard(id);
      this.idDashboard = dados[0].bda_embedded;

      this.supersetEmbeddedSdk = document.createElement("script");
      this.supersetEmbeddedSdk.setAttribute(
        "src",
        "https://unpkg.com/@superset-ui/embedded-sdk@0.1.0-alpha.9/bundle/index.js"
      );
      document.head.appendChild(this.supersetEmbeddedSdk);

      this.$forceUpdate();
      this.token = await this.fetchGuestTokenFromBackend(this.idDashboard);

      this.exibirLoading = false;
      window.supersetEmbeddedSdk.embedDashboard({
        id: this.idDashboard,
        supersetDomain: this.supersetDomain,
        mountPoint: document.getElementById("supersetMount"),
        fetchGuestToken: () => this.token,
        dashboardUiConfig: {
          hideTitle: true,
          hideChartControls: true,
          filters: {
            expanded: true
          }
        }
      });
      this.iniciarAtualizacaoToken();
    },
    iniciarLayout() {
      let newConfig = JSON.parse(JSON.stringify(config));
      this.$store.dispatch(SET_BREADCRUMB, [{ title: "BI" }]);
      this.$store.dispatch(ADD_BODY_CLASSNAME, "aside-minimize");
      newConfig.header.self.display = false;
      newConfig.subheader.display = false;
      newConfig.styleContetnt = { padding: "0" };
      newConfig.styleContainer = {
        "max-width": "100%",
        margin: 0,
        "padding-right": 0
      };
      newConfig.wrapper = { "padding-top": 0 };
      newConfig.scrollTop.self.display = false;
      newConfig.aside.self.minimize.hoverable = true;
      this.$store.dispatch(SET_LAYOUT_CONFIG, newConfig);
    },
    /**
     * Metodo para atualizar o token do BI no superset
     * @returns String - Token
     */
    iniciarAtualizacaoToken() {
      this.interval = Utils.setInterval(async () => {
        this.token = await this.fetchGuestTokenFromBackend(this.idDashboard);
      }, 240000);
    },
    async solicitarCodigoDashboard(id) {
      let { data } = await ApiService.get(`bi/dashboard/${id}`);
      return data;
    }
  },
  beforeDestroy() {
    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "aside-minimize");
    clearInterval(this.interval);
    var elcss = document.getElementById("cssNps");
    if (elcss) elcss.remove();
    var elapp = document.getElementById("appNps");
    if (elapp) elapp.remove();
  }
};
</script>

<style>
#supersetMount > iframe {
  width: 100%;
  height: 100%;
  border: 0;
  overflow: hidden;
}

#supersetMount > iframe #document body {
  background-color: transparent !important;
}

.opine
  .v-progress-circular--indeterminate:not(.v-progress-circular--visible)
  > svg,
.opine
  .v-progress-circular--indeterminate:not(.v-progress-circular--visible)
  .v-progress-circular__overlay {
  animation-play-state: running !important;
}
.opine .statistics {
  width: 100%;
}
.aside-menu .menu-nav > .menu-item > .menu-heading .menu-text,
.aside-menu .menu-nav > .menu-item > .menu-link .menu-text {
  font-weight: 400 !important;
  font-size: 13px !important;
  text-transform: initial !important;
  font-family: Poppins, Helvetica, "sans-serif" !important;
}
.aside-menu .menu-nav > .menu-item > .menu-heading .menu-icon,
.aside-menu .menu-nav > .menu-item > .menu-link .menu-icon {
  font-size: 19.5px !important;
}
.opine div.flex.xs12.md3:first-of-type {
  padding-left: 0px !important;
}
</style>
